<template>
  <div class="invoices-content">
    <div
      v-if="!loaded"
      style="
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="loader" />
    </div>
    <div
      style="margin-top: 48px"
      v-if="
        loaded && (!invoicesData || (invoicesData && invoicesData.length < 1))
      "
    >
      <p class="heading-geo32 no-results">
        {{ $t("NoResultsFound") }}
      </p>
      <p class="clear-filters-button" @click="$emit('clearFilters')">
        {{ $t("ClearAllFilters") }}
      </p>
    </div>
    <template v-else>
      <div v-if="loaded" class="invoices-content-table-header">
        <BaseToggle
          style="white-space: nowrap"
          v-model="summariesWithVAT"
          :label="$t('SummariesWithVAT')"
        />
        <div
          class="invoices-content-table-header-card"
          style="background-color: #e8f7f8"
        >
          <div class="font-16-regular">{{ $t("TotalInvoice") }}:</div>
          <div style="padding-left: 4px" class="font-16-bold">
            {{ $nMapper.price(headerData.totalInvoice, "€") }}
          </div>
        </div>
        <div
          class="invoices-content-table-header-card"
          style="background-color: #e8f7f8"
        >
          <div class="font-16-regular">{{ $t("Paid") }}:</div>
          <div style="padding-left: 4px" class="font-16-bold">
            {{ $nMapper.price(headerData.paid, "€") }}
          </div>
        </div>
        <div
          class="invoices-content-table-header-card"
          style="background-color: #fff0f0"
        >
          <div class="font-16-regular">{{ $t("NotPaid") }}:</div>
          <div style="padding-left: 4px" class="font-16-bold">
            {{ $nMapper.price(headerData.notPaid, "€") }}
          </div>
        </div>
        <div
          class="invoices-content-table-header-card"
          style="background-color: #fff0f0"
        >
          <div class="font-16-regular">{{ $t("Overdue") }}:</div>
          <div style="padding-left: 4px" class="font-16-bold">
            {{ $nMapper.price(headerData.overdue, "€") }}
          </div>
        </div>
      </div>
      <template v-if="activeInvoicesTab === 'invoices'">
        <Table
          v-if="loaded"
          :fields="invoiceTableFields"
          :dataset="invoicesData"
          :update-key="tableUpdater"
          :invoices-view="true"
          @reloadInvoices="$emit('getInvoices')"
          @invoiceClicked="(id) => openSidepanel('invoices', id)"
          :selected-sort="selectedSort"
          @sortInvoices="sortInvoices"
        />
      </template>
      <template v-else>
        <Table
          v-if="loaded"
          :fields="invoiceBrokerTableFields"
          :dataset="invoicesData"
          :update-key="tableUpdater"
          :invoices-view="true"
          @reloadInvoices="$emit('getInvoices')"
          @invoiceClicked="(emitData) => openSidepanel('invoicesUser', emitData.brokerId)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import Table from "@/components/common/Table";
import BaseToggle from "@/components/common/BaseToggle";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Content",
  components: { BaseToggle, Table },
  props: ["invoicesData", "headerData", "loaded", "selectedSort"],
  watch: {
    invoicesData() {
      this.tableUpdater++;
    },
    summariesWithVAT(value) {
      this.setInvoiceSummariesShowVAT(value);
      this.$emit("getInvoices");
    },
  },
  computed: {
    ...mapGetters(["invoiceSummariesWithVat", "activeInvoicesTab"]),
  },
  created() {
    if (this.invoiceSummariesWithVat) {
      this.summariesWithVAT = this.invoiceSummariesWithVat;
    }
  },
  data() {
    return {
      summariesWithVAT: false,
      tableUpdater: 0,
      invoiceTableFields: [
        {
          fieldValue: this.$t("InvoiceDate"),
          jsonValue: "invoiceDate",
          style: "min-width: 136px; max-width: 136px; height: 52px;",
        },
        {
          fieldValue: this.$t("InvoiceNumber"),
          jsonValue: "number",
          style: "min-width: 136px; max-width: 136px; height: 52px;",
        },
        {
          fieldValue: this.$t("InvoiceType"),
          jsonValue: "invoiceType",
          style: "min-width: 120px; max-width: 120px; height: 52px;",
        },
        {
          fieldValue: this.$t("InvoiceOwner"),
          jsonValue: "broker",
          style: "min-width: 160px; max-width: 160px; height: 52px;",
        },
        {
          fieldValue: this.$t("Project"),
          jsonValue: "invoiceProject",
          style: "min-width: 200px; max-width: 200px; height: 52px;",
        },
        {
          fieldValue: this.$t("sum"),
          jsonValue: "sum",
          style: "min-width: 112px; max-width: 112px; height: 52px;",
        },
        {
          fieldValue: this.$t("TotalSumWithVAT"),
          jsonValue: "sumVAT",
          style: "min-width: 112px; max-width: 112px; height: 52px;",
        },
        {
          fieldValue: this.$t("Unpaid"),
          jsonValue: "unpaid",
          style: "min-width: 112px; max-width: 112px; height: 52px;",
        },
        {
          fieldValue: this.$t("Status"),
          jsonValue: "status",
          style: "min-width: 176px; max-width: 176px; height: 52px;",
        },
      ],
      invoiceBrokerTableFields: [
        {
          fieldValue: this.$t("FirstName"),
          jsonValue: "firstName",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("LastName"),
          jsonValue: "lastName",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("Paid"),
          jsonValue: "paid",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("NotPaid"),
          jsonValue: "unpaid",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("sum"),
          jsonValue: "sum",
          style: "min-width:160px; max-width:160px; height:56px",
        },
        {
          fieldValue: this.$t("TotalSumWithVAT"),
          jsonValue: "sumVAT",
          style: "width: 100%; height:56px",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["setInvoiceSummariesShowVAT"]),
    updateInvoiceStatus(data) {
      if (
        this.invoicesData.find((invoice) => invoice._id === data.invoiceId)
          .status === data.newStatus
      )
        return;
      this.tableUpdater++;
      this.$emit("updateInvoiceStatus", data);
    },
    sortInvoices(data) {
      this.$emit("sortInvoices", data);
    },
  },
};
</script>

<style scoped>
.invoices-content {
  padding: 24px 88px 24px 112px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 1400px;
  width: fit-content;
  height: 100%;
}

.invoices-content-table-header {
  height: 72px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 16px;
}

.invoices-content-table-header-card {
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
