var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "invoices-content"
  }, [!_vm.loaded ? _c('div', {
    staticStyle: {
      "display": "flex",
      "height": "100%",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e(), _vm.loaded && (!_vm.invoicesData || _vm.invoicesData && _vm.invoicesData.length < 1) ? _c('div', {
    staticStyle: {
      "margin-top": "48px"
    }
  }, [_c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoResultsFound")) + " ")]), _c('p', {
    staticClass: "clear-filters-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('clearFilters');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ClearAllFilters")) + " ")])]) : [_vm.loaded ? _c('div', {
    staticClass: "invoices-content-table-header"
  }, [_c('BaseToggle', {
    staticStyle: {
      "white-space": "nowrap"
    },
    attrs: {
      "label": _vm.$t('SummariesWithVAT')
    },
    model: {
      value: _vm.summariesWithVAT,
      callback: function ($$v) {
        _vm.summariesWithVAT = $$v;
      },
      expression: "summariesWithVAT"
    }
  }), _c('div', {
    staticClass: "invoices-content-table-header-card",
    staticStyle: {
      "background-color": "#e8f7f8"
    }
  }, [_c('div', {
    staticClass: "font-16-regular"
  }, [_vm._v(_vm._s(_vm.$t("TotalInvoice")) + ":")]), _c('div', {
    staticClass: "font-16-bold",
    staticStyle: {
      "padding-left": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.headerData.totalInvoice, "€")) + " ")])]), _c('div', {
    staticClass: "invoices-content-table-header-card",
    staticStyle: {
      "background-color": "#e8f7f8"
    }
  }, [_c('div', {
    staticClass: "font-16-regular"
  }, [_vm._v(_vm._s(_vm.$t("Paid")) + ":")]), _c('div', {
    staticClass: "font-16-bold",
    staticStyle: {
      "padding-left": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.headerData.paid, "€")) + " ")])]), _c('div', {
    staticClass: "invoices-content-table-header-card",
    staticStyle: {
      "background-color": "#fff0f0"
    }
  }, [_c('div', {
    staticClass: "font-16-regular"
  }, [_vm._v(_vm._s(_vm.$t("NotPaid")) + ":")]), _c('div', {
    staticClass: "font-16-bold",
    staticStyle: {
      "padding-left": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.headerData.notPaid, "€")) + " ")])]), _c('div', {
    staticClass: "invoices-content-table-header-card",
    staticStyle: {
      "background-color": "#fff0f0"
    }
  }, [_c('div', {
    staticClass: "font-16-regular"
  }, [_vm._v(_vm._s(_vm.$t("Overdue")) + ":")]), _c('div', {
    staticClass: "font-16-bold",
    staticStyle: {
      "padding-left": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.headerData.overdue, "€")) + " ")])])], 1) : _vm._e(), _vm.activeInvoicesTab === 'invoices' ? [_vm.loaded ? _c('Table', {
    attrs: {
      "fields": _vm.invoiceTableFields,
      "dataset": _vm.invoicesData,
      "update-key": _vm.tableUpdater,
      "invoices-view": true,
      "selected-sort": _vm.selectedSort
    },
    on: {
      "reloadInvoices": function ($event) {
        return _vm.$emit('getInvoices');
      },
      "invoiceClicked": function (id) {
        return _vm.openSidepanel('invoices', id);
      },
      "sortInvoices": _vm.sortInvoices
    }
  }) : _vm._e()] : [_vm.loaded ? _c('Table', {
    attrs: {
      "fields": _vm.invoiceBrokerTableFields,
      "dataset": _vm.invoicesData,
      "update-key": _vm.tableUpdater,
      "invoices-view": true
    },
    on: {
      "reloadInvoices": function ($event) {
        return _vm.$emit('getInvoices');
      },
      "invoiceClicked": function (emitData) {
        return _vm.openSidepanel('invoicesUser', emitData.brokerId);
      }
    }
  }) : _vm._e()]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }